const Footer = () => {
    return (
        <div className="footer-area">
      <div className="Container">
        <div className="row">
          <div className="col-md-12">
            <div className="footer-logo">
              <img src="assets/img/logo.svg" alt="" />
              Chain Integrity
            </div>
          </div>
        </div>
        <div className="row mb-100">
          <div className="col-lg-6 col-md-12" style={{paddingBottom: '20px'}}>
            <h3>The Web 3.0 Ethical&nbsp;<br />Hacking Company</h3>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="widget">
              <h2>TECHNOLOGY</h2>
              <ul>
                <li><a href="/">Approach</a></li>
                <li><a href="/">Products</a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="widget">
              <h2>ABOUT US</h2>
              <ul>
                <li><a href="/">Partners</a></li>
                <li><a href="/">News</a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="widget">
              <h2>JOIN US</h2>
              <ul>
                <li><a href="/">Careers</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mb-100">
          <div className="col-lg-6">
            <div className="footer-social">
              <h2>CONNECT</h2>
              <ul>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://twitter.com/chainintegrity"><img src="assets/img/twitter.svg" alt="" /></a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://t.me/ChainIntegrity"><img src="assets/img/telegram.svg" alt="" /></a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://medium.com/@chainintegrity"><img src="assets/img/medium.svg" alt="" /></a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://github.com/chainintegrity"><img src="assets/img/github.svg" alt="" /></a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/chainintegrity"><img src="assets/img/linkedin.svg" alt="" /></a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="widget">
              <h2>STAY UP TO DATE</h2>
              <form action="">
                <div className="subscribe-box">
                  <input type="email" placeholder="Type your email" />
                  <input type="submit" value="Submit" />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 order-lg-1">
            <div className="footer-menu">
              <ul>
                <li><a href="/">Terms & conditions</a></li>
                <li><a href="/">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 order-lg-0">
            <div className="copyright">
              COPYRIGHT &copy; 2021 CHAIN INTEGRITY. ALL RIGHTS RESERVED
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default Footer
