import NavBar from "../components/NavBar";
import { useEffect, useState } from "react";
import Footer from "../components/Footer";

function Home() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [state, setState] = useState({
    first: true,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
  });

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);
  return (
    <div className={`${menuOpen && "menu-open"}`}>
      {loader && <div className="preloader"></div>}
      <NavBar setMenuOpen={setMenuOpen} />
      <div className="hero-area">
        <div className="Container">
          <div className="row align-items-center">
            <div className="col-lg-8 order-lg-1">
              <div className="hero-video">
                <div className="hero-video-container">
                  <video width="100%" height="auto" playsinline autoPlay muted loop>
                    <source type="video/mp4" src="/assets/motions/Main_Video_1.mp4" />
                  </video>
                </div>
              </div>
            </div>
            <div className="col-lg-4 order-lg-0 web3-into-container" style={{ paddingRight: 0 }}>
              <div className="hero-text">
                <h2>
                  The Web 3.0 Ethical&nbsp;
                  <br />
                  Hacking Company
                </h2>
                <p>Safeguarding inventors and investors with fit-for-purpose security research to identify and mitigate potential risk.</p>
              </div>
              <div className="scroll-box bg-transparent">
                <a href="#mission" className="bg-transparent">
                  <span className="image">
                    <img src="/assets/img/arrow-down.svg" alt="" style={{ background: "transparent" }} />
                  </span>
                  Explore
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="mission" className="section-area">
        <div className="Container">
          <div className="row">
            <div className="col-lg-12">
              <p className="border-title">OUR MISSION</p>
            </div>
            <div className="col-lg-7">
              <div className="section-title">
                <h2>
                  Safeguarding both&nbsp;
                  <br />
                  inventors and investors.
                </h2>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="section-text">
                <p>
                  Work with a company that is committed to&nbsp;
                  <br />
                  improve the security of decentralized finance for&nbsp;
                  <br />
                  both inventors and investors by providing first-
                  <br />
                  class security research.
                </p>
                <p>
                  Our team consist of threat modelling specialists and&nbsp;
                  <br />
                  smart contract auditors with in-depth proficiency in&nbsp;
                  <br />
                  activities such as threat modelling, security auditing,&nbsp;
                  <br />
                  and risk analysis - enabling coverage of the entire&nbsp;
                  <br />
                  spectrum of actors in decentralized finance,&nbsp;
                  <br />
                  including projects, venture capitals, HNWIs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="approch" className="section-area approch">
        <div className="Container">
          <div className="row">
            <div className="col-lg-12">
              <p className="border-title">OUR APPROCH</p>
            </div>
            <div className="col-lg-7">
              <div className="section-title mb-3">
                <h2 className="bg-transparent">
                  Scope. Discover.&nbsp;
                  <br />
                  Analyze. Secure.
                </h2>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="section-text mb-100">
                <p className="bg-transparent">
                  Every assessment begins with scope fitting and &nbsp;
                  <br />
                  document sharing. Next, the discovery phase&nbsp;
                  <br />
                  proceeds, followed by testing, code analysis and&nbsp;
                  <br />
                  risk modelling. The cooperation is finalized once &nbsp;
                  <br />
                  the client receives a personalized deliverable.
                </p>
              </div>
            </div>
          </div>
          <div className="row cards-container">
            <div className="col-md-6 col-lg-3">
              <div className="promo-box">
                <div className="promo-icon">
                  <img src="assets/img/promo-1.svg" alt="" />
                </div>
                <div className="promo-text">
                  <h4>Scope</h4>
                  <p style={{ height: "84px" }}>
                    Leverage expert intuition &nbsp;
                    <br />
                    to scope the most fitting &nbsp;
                    <br />
                    security undertaking&nbsp;
                    <br />
                    based on valuable &nbsp;
                    <br />
                    objectives.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="promo-box">
                <div className="promo-icon">
                  <img src="assets/img/promo-2.svg" alt="" />
                </div>
                <div className="promo-text">
                  <h4>Discover</h4>
                  <p style={{ height: "84px" }}>
                    Cooperative information &nbsp;
                    <br />
                    and codebase sharing for &nbsp;
                    <br />
                    comprehension and&nbsp;
                    <br />
                    seamless project&nbsp;
                    <br />
                    discovery.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="promo-box">
                <div className="promo-icon">
                  <img src="assets/img/promo-3.svg" alt="" />
                </div>
                <div className="promo-text">
                  <h4>Analyze</h4>
                  <p>
                    Piggyback on extensive&nbsp;
                    <br />
                    security testing expertise &nbsp;
                    <br />
                    using state-of-the-art&nbsp;
                    <br />
                    tooling and first-class &nbsp;
                    <br />
                    manual verification.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="promo-box">
                <div className="promo-icon">
                  <img src="assets/img/promo-4.svg" alt="" />
                </div>
                <div className="promo-text">
                  <h4>Secure</h4>
                  <p style={{ height: "84px" }}>
                    Receive a personalized&nbsp;
                    <br />
                    security research &nbsp;
                    <br />
                    deliverable to expose&nbsp;
                    <br />
                    threats and optimize &nbsp;
                    <br />
                    security hygiene.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services" className="section-area service bg-transparent">
        <div className="Container">
          <div className="row">
            <div className="col-lg-12" id="approch_tab">
              <p className="border-title position-relative">OUR SERVICES</p>
              <div className="tab-content service-list">
                {state.first && (
                  <div className="tab-pane fade in show active">
                    <div className="row">
                      <div className="col-lg-6 order-lg-1 service-image-container">
                        <div className="service-image">
                          <video width="100%" height="auto" src="assets/motions/Main_Video_2.mp4" playsinline autoPlay loop muted />
                        </div>
                      </div>
                      <div className="col-lg-6 order-lg-0">
                        <div className="section-text">
                          <h5 className="sub-title">01</h5>
                          <h2 className=" bg-transparent">Fit-for-Purpose Customization</h2>
                          <p className="position-relative bg-transparent">
                            Whether you are an inventor or investor&nbsp;
                            <br />
                            in decentralized finance, our security&nbsp;
                            <br />
                            research ensures that we maximize&nbsp;
                            <br />
                            personalized vigilance.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {state.second && (
                  <div id="tab-2" className="tab-pane fade show active">
                    <div className="row">
                      <div className="col-lg-6 order-lg-1 service-image-container">
                        <div className="service-image">
                          <video width="100%" height="auto" src="assets/motions/Main_Video_3.mp4" playsinline autoPlay loop muted />
                        </div>
                      </div>
                      <div className="col-lg-6 order-lg-0">
                        <div className="section-text">
                          <h5 className="sub-title">02</h5>
                          <h2 className="bg-transparent">Security Auditing & Hardening</h2>
                          <p className="position-relative bg-transparent">
                            Leverage a team of smart contract auditors&nbsp;
                            <br />
                            using first-class tooling and best practices&nbsp;
                            <br />
                            to identify and prevent security issues&nbsp;
                            <br />
                            from being exploited.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {state.third && (
                  <div id="tab-3" className="tab-pane fade show active">
                    <div className="row">
                      <div className="col-lg-6 order-lg-1 service-image-container">
                        <div className="service-image">
                          <video width="100%" height="auto" src="assets/motions/Main_Video_4.mp4" playsinline autoPlay loop muted />
                        </div>
                      </div>
                      <div className="col-lg-6 order-lg-0">
                        <div className="section-text">
                          <h5 className="sub-title">03</h5>
                          <h2 className="bg-transparent">Code Optimization & Engineering</h2>
                          <p className="position-relative bg-transparent">
                            Solve critical bottlenecks in your smart&nbsp;
                            <br />
                            contracts using our keen proficiency of the&nbsp;
                            <br />
                            EVM, to rapidly optimize gas cost, safety,&nbsp;
                            <br />
                            and performance.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {state.fourth && (
                  <div id="tab-4" className="tab-pane fade show active">
                    <div className="row">
                      <div className="col-lg-6 order-lg-1 service-image-container">
                        <div className="service-image">
                          <video width="100%" height="auto" src="assets/motions/Main_Video_5.mp4" playsinline autoPlay loop muted />
                        </div>
                      </div>
                      <div className="col-lg-6 order-lg-0">
                        <div className="section-text">
                          <h5 className="sub-title">04</h5>
                          <h2 className="bg-transparent">Threat Modelling & Simulation</h2>
                          <p className="position-relative bg-transparent">
                            Perfect your reflection or learn about &nbsp;
                            <br />
                            project-specific threat vectors using threat &nbsp;
                            <br />
                            profiling personalized for dodging genuinely &nbsp;
                            <br />
                            appearing scams in disguise.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {state.fifth && (
                  <div id="tab-5" className="tab-pane fade show active">
                    <div className="row">
                      <div className="col-lg-6 order-lg-1 service-image-container">
                        <div className="service-image">
                          <video width="100%" height="auto" src="assets/motions/Main_Video_6.mp4" playsinline autoPlay loop muted />
                        </div>
                      </div>
                      <div className="col-lg-6 order-lg-0">
                        <div className="section-text">
                          <h5 className="sub-title">05</h5>
                          <h2 className="bg-transparent">
                            Risk Analysis &&nbsp;
                            <br />
                            Ranking
                          </h2>
                          <p className="position-relative bg-transparent">
                            Realize the impact and likelihood of&nbsp;
                            <br />
                            exploitation from KRIs designed using&nbsp;
                            <br />
                            novel methods for quantifying risk in&nbsp;
                            <br />
                            decentralized finance.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="tab-title">
                <ul className="nav nav-tab">
                  <li className="nav-item">
                    <a
                      href="#approch_tab"
                      data-toggle="tab"
                      onClick={() => setState(false) || setState({ first: true })}
                      className={`nav-link ${state.first && "active"}`}
                    >
                      <span>01</span>Fit-for-Purpose Customization
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#approch_tab"
                      data-toggle="tab"
                      onClick={() => setState(false) || setState({ second: true })}
                      className={`nav-link ${state.second && "active"}`}
                    >
                      <span>02</span>Security Auditing & Hardening
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#approch_tab"
                      data-toggle="tab"
                      className={`nav-link ${state.third && "active"}`}
                      onClick={() => setState(false) || setState({ third: true })}
                    >
                      <span>03</span>Code Optimization & Engineering
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#approch_tab"
                      data-toggle="tab"
                      className={`nav-link ${state.fourth && "active"}`}
                      onClick={() => setState(false) || setState({ fourth: true })}
                    >
                      <span>04</span>Threat Modelling & Simulation
                    </a>
                  </li>
{/*                   <li className="nav-item">
                    <a
                      href="#approch_tab"
                      data-toggle="tab"
                      className={`nav-link ${state.fifth && "active"}`}
                      onClick={() => setState(false) || setState({ fifth: true })}
                    >
                      <span>05</span>Risk Analysis & Ranking
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="technologies" className="section-area">
        <div className="Container">
          <div className="row">
            <div className="col-lg-6">
              <p className="border-title">OUR TECHNOLOGY</p>
              <div className="section-title">
                <h2>
                  Following technologies&nbsp;
                  <br />
                  is our forte.
                </h2>
                <p className="w-75 center-mobile">
                  Most security breaches arise from protocol&nbsp;
                  <br />
                  composability. We specialize in projects&nbsp;
                  <br />
                  that draw on following technologies.
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <ul className="tech-list">
                <li>
                  <img src="/assets/img/tech-1.svg" alt="" />
                </li>
                <li>
                  <img src="/assets/img/tech-2.svg" alt="" />
                </li>
                <li>
                  <img src="/assets/img/tech-3.svg" alt="" />
                </li>
                <li>
                  <img src="/assets/img/tech-4.svg" alt="" />
                </li>
                <li>
                  <img src="/assets/img/tech-5.svg" alt="" />
                </li>
                <li>
                  <img src="/assets/img/tech-6.svg" alt="" />
                </li>
                <li>
                  <img src="/assets/img/tech-7.svg" alt="" />
                </li>
                <li>
                  <img src="/assets/img/tech-8.svg" alt="" />
                </li>
                <li>
                  <img src="/assets/img/tech-9.svg" alt="" />
                </li>
                <li>
                  <img src="/assets/img/tech-10.svg" alt="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="contact" className="section-area contact">
        <div className="Container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="section-title bg-transparent text-center">
                <h1 className="bg-transparent">
                  Ready To Safeguard Your&nbsp;
                  <br />
                  Project & Community?
                </h1>
                <p className="bg-transparent">
                  Let's explore how we can help safeguard&nbsp;
                  <br />
                  your project and community.
                </p>
              </div>
              <div className="talk-style">
                {/* <Link to="/contact"> */}
                <a href="/contact">Let’s Talk</a>
                {/* </Link> */}
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
        <canvas id="lasers"></canvas>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
