import { Link } from "react-router-dom";

const NavBar = ({ setMenuOpen }) => {
  return (
    <div className="header-area sticky-top" style={{ backgroundColor: "#000" }}>
      <div className="Container">
        <div className="row align-items-center">
          <nav className="navbar navbar-expand-md w-100">
            <Link to="/">
              <a href="/" className="navbar-brand">
                <img src="/assets/img/logo.svg" className="" alt="" style={{maxWidth:"50px"}} />
              </a>
            </Link>
            <div
              id="navbar"
              className="collapse navbar-collapse justify-content-center"
            >
              <ul className="nav navbar-nav">
                <li className="menu-item">
                  <a href="#approch" className="nav-link">
                    Approach
                  </a>
                </li>
                <li className="menu-item">
                  <a href="#services" className="nav-link">
                    Services
                  </a>
                </li>
                <li className="menu-item">
                  <a href="#technologies" className="nav-link">
                    Technologies
                  </a>
                </li>
                <li className="menu-item">
                  <a href="/" className="nav-link">
                    Partners
                  </a>
                </li>
                <li className="menu-item">
                  <a href="/" className="nav-link">
                    News
                  </a>
                </li>
                <li className="menu-item">
                  <a href="#contact" className="nav-link">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div className="social-bookmarks">
              <ul>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://twitter.com/chainintegrity">
                    <i className="bi bi-twitter"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/chainintegrity">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
            <button
              className="menu-button d-md-none"
              onClick={() => setMenuOpen(true)}
            >
              <img src="/assets/img/menu.svg" alt="" />
            </button>
            <div className="menu-area">
              <button className="menu-close" onClick={() => setMenuOpen(false)}>
                <img src="/assets/img/menu-close.svg" alt="" />
              </button>
              <div className="Container">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="menu">
                      <ul>
                        <li>
                          <a
                            href="index.html#"
                            className="menu-inner-link-clicked"
                          >
                            Home
                          </a>
                        </li>
                        <li>
                          <a
                            href="index.html#approch"
                            className="menu-inner-link-clicked"
                          >
                            Approach
                          </a>
                        </li>
                        <li>
                          <a
                            href="index.html#services"
                            className="menu-inner-link-clicked"
                          >
                            Services
                          </a>
                        </li>
                        <li>
                          <a
                            href="index.html#technologies"
                            className="menu-inner-link-clicked"
                          >
                            Technologies
                          </a>
                        </li>
                        <li>
                          <a href="/">Partners</a>
                        </li>
                        <li>
                          <a href="/">News</a>
                        </li>
                        <li>
                          <a
                            href="index.html#contact"
                            className="menu-inner-link-clicked"
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="menu-side">
                      <h2>CONNECT</h2>
                      <ul className="mb-4">
                        <li>
                          <a href="/">Twitter</a>
                        </li>
                        <li>
                          <a href="/">Telegram</a>
                        </li>
                        <li>
                          <a href="/">Medium</a>
                        </li>
                        <li>
                          <a href="/">Github</a>
                        </li>
                        <li>
                          <a href="/">Linkedin</a>
                        </li>
                      </ul>
                      <h2>CONTACT</h2>
                      <ul>
                        <li>
                          <a href="mailto:info@chainintegrity.com">
                            info@chainintegrity.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
