import { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import axios from "axios";

const Contact = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [form, setForm] = useState({ status: "initial" });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setForm({ ...form, status: "sending" });
    try {
      await axios.post("https://us-central1-lovergifts-a55c0.cloudfunctions.net/contactUs", form);
      setForm({ ...form, status: "success" });
    } catch (error) {
      setForm({ ...form, status: "error" });
    }
  };
  return (
    <div className={`${menuOpen && "menu-open"}`}>
      <div className="header-area sticky-top" style={{ backgroundColor: "#000" }}>
        <div className="container">
          <div className="row align-items-center">
            <nav className="navbar navbar-expand-md w-100">
              <Link to="/">
                <a href="/" className="navbar-brand">
                  <img src="assets/img/logo.svg" alt="" style={{ maxWidth: "50px" }} />
                </a>
              </Link>
              <button className="menu-button" onClick={() => setMenuOpen(true)}>
                <img src="assets/img/menu.svg" alt="" />
              </button>
            </nav>
          </div>
        </div>
      </div>

      <div className="menu-area">
        <button className="menu-close" onClick={() => setMenuOpen(false)}>
          <img src="assets/img/menu-close.svg" alt="" />
        </button>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="menu">
                <ul>
                  <li>
                    <Link to="/">
                      {" "}
                      <a href="/">Home</a>
                    </Link>
                  </li>
                  <li>
                    <a href="/#approch">Approach</a>
                  </li>
                  <li>
                    <a href="/#services">Services</a>
                  </li>
                  <li>
                    <a href="/#technologies">Technologies</a>
                  </li>
                  <li>
                    <a href="/">Partners</a>
                  </li>
                  <li>
                    <a href="/">News</a>
                  </li>
                  <li>
                    <a href="/#contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="menu-side">
                <span style={{ color: "#4b4b4b" }}>CONNECT</span>
                <ul className="mb-4">
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://twitter.com/chainintegrity">
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://t.me/ChainIntegrity">
                      Telegram
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://medium.com/@chainintegrity">
                      Medium
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://github.com/chainintegrity">
                      Github
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/chainintegrity">
                      Linkedin
                    </a>
                  </li>
                </ul>
                <span style={{ color: "#4b4b4b" }}>CONTACT</span>
                <ul>
                  <li>
                    <a href="mailto:info@chainintegrity.com">info@chainintegrity.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="contact-form">
                <div className="contact-box">
                  <h2 className="mb-2">Request Security Audit Quote.</h2>
                  <p>
                    Use the form below or
                    <a href="mailto:info@chainintegrity.com" style={{ textDecoration: "none" }}>
                      <strong> send us an email: info@chainintegrity.com</strong>
                    </a>
                    .
                  </p>
                </div>
                <div className="form-wraper" id="form_container">
                  <form method="post" id="reused_form">
                    <div className="form-box">
                      <label htmlFor="project">Project</label>
                      <input
                        id="project"
                        type="text"
                        placeholder="Provide the name of the project."
                        name="project"
                        required
                        value={form.project}
                        onChange={(e) => setForm({ ...form, project: e.target.value })}
                      />
                    </div>
                    <div className="form-box">
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        type="text"
                        placeholder="Provide the email of the project."
                        name="email"
                        size="50"
                        value={form.email}
                        onChange={(e) => setForm({ ...form, email: e.target.value })}
                        required
                      />
                    </div>
                    <div className="form-box">
                      <label htmlFor="telegram">Telegram (optional)</label>
                      <input
                        id="telegram"
                        type="text"
                        placeholder="Provide the handle."
                        name="telegram"
                        value={form.telegram}
                        onChange={(e) => setForm({ ...form, telegram: e.target.value })}
                      />
                    </div>
                    <div className="form-box">
                      <label htmlFor="code">Source Code (optional)</label>
                      <input
                        id="code"
                        type="url"
                        placeholder="Provide the source code link."
                        name="source_code"
                        value={form.codeSourceLink}
                        onChange={(e) => setForm({ ...form, codeSourceLink: e.target.value })}
                      />
                    </div>
                    <div className="form-box">
                      <label htmlFor="additional">Additional Information</label>
                      <input
                        id="additional"
                        type="text"
                        placeholder="Provide a description of the scope of work."
                        name="addinional_information"
                        required
                        value={form.additionalInfo}
                        onChange={(e) => setForm({ ...form, additionalInfo: e.target.value })}
                      />
                    </div>
                    <div className="form-box">
                      <button className="btn-submit" type="submit" id="btnContactUs" name="submit" onClick={handleSubmit}>
                        {form.status === "sending" ? " Sending... 📬 ⏳" : "Send Inquiry"}
                      </button>
                    </div>
                  </form>
                  {form.status === "success" && (
                    <div id="success_message">
                      <h2 className="mb-0">Your request has been submitted successfully!</h2>
                      <p>
                        The blast radius for Web 3.0 security exploits is devastating for company reputation and project investors. Kudos for understanding!
                      </p>
                      <h4>One of our representatives will process the inquiry and follow up shortly.</h4>
                    </div>
                  )}
                  {form.status === "error" && (
                    <div id="error_message">
                      <h2 className="mb-0">Error</h2>
                      <p>Sorry there was an error sending your form.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <canvas id="lasers"></canvas>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
